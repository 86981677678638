import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Consultoría Drupal, arquitectura, pliegos técnicos y RFP." description="Nuestros servicios de consultoría van desde definir la arquitectura del proyecto, a preparar pliegos técnicos y ayudar con la elaboración de propuestas técnicas y RFP." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Consultoria Drupal</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Nuestra especialización en Drupal nos da una visión global sobre cómo utilizar Drupal para desarrollar tu proyecto.</p>
      </div>
    </div>
  </section>
  <section className="pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Tipos de trabajos de consultoría</strong>
          </h2>
          <p className="lead pt-3">A lo largo de estos años hemos ayudado a empresas con distintas tipologías de proyectos de consultoría.</p>
          <h3 className="pt-4">Arquitectura de proyecto</h3>
          <p className="lead">Definimos la arquitectura del proyecto Drupal para que tu equipo desarrolle el proyecto. Nos podemos encargar de toda la dirección técnica del proyecto, toma de decisiones técnicas, análisis de riesgos y actuar ante imprevistos.</p>
          <h3 className="pt-2">Definición de pliegos</h3>
          <p className="lead">Preparamos pliegos técnicos para empresas privadas y administraciones públicas que quieren publicar un concurso de desarrollo de software con Drupal como CMS.</p>
          <h3 className="pt-2">Elaboración de propuestas técnicas</h3>
          <p className="lead">Ayudamos a preparar propuestas técnicas y RFPs para presentar a tus propuestas de desarrollo en Drupal.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
